<template>
  <div class="wrapper">
    <div class="main-content">
      <section>
        <div class="container">
          <div class="row">
            <h1>{{ rewardFunctions.title }}</h1>
            <p>{{ rewardFunctions.description }}</p>
            <h2>Reward Functions List</h2>
            <div class="d-flex justify-content-center align-items-center">
              <div class="row">
                <div
                  v-for="(rewardFunction, index) in rewardFunctions.rewardFunctions"
                  :key="index"
                  class="col-md-12 mb-4 pattern-card"
                >
                  <div class="card zoom-card" @mouseenter="zoomIn" @mouseleave="zoomOut">
                    <div class="card-body">
                      <h3 class="card-title">{{ rewardFunction.name }}</h3>
                      <p class="card-text">{{ rewardFunction.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RewardFunctions',
  data() {
    return {
      rewardFunctions: {
        title: 'Reward Functions for AI Trading Agents',
        description:
          'Explore a variety of reward functions employed by our AI trading agents to evaluate and optimize trading strategies. These reward functions play a crucial role in reinforcing desired behaviors and enhancing the performance of AI-driven trading algorithms.',
        rewardFunctions: [
          {
            name: 'Action Profit',
            description:
              "The 'ActionProfit' function calculates the potential profit or loss associated with a given action in a financial context. It considers factors such as transaction costs and market price fluctuations to determine the financial outcome of taking that action. This function is an essential component in evaluating the performance and profitability of different investment actions, helping users make informed decisions in financial scenarios."
          },
          {
            name: 'Reward Analysis',
            description:
              'This function analyzes the potential rewards associated with a financial action, factoring in transaction costs, peak values, drawdown, and risk adjustments. It assists users in evaluating the attractiveness of different investment actions.'
          },
          {
            name: 'Evaluate Peak Price',
            description:
              'Evaluates the peak price in a financial dataset, beginning from the given index and accounting for the position type (long or short).'
          },
          {
            name: 'Risk-Adjusted Performance',
            description:
              'Calculates the risk-adjusted reward for a financial action, considering factors such as transaction cost, drawdown, and position type (long or short).'
          },
          {
            name: 'Peak-Enhanced Reward',
            description:
              'Determines the reward for a financial action, emphasizing risk adjustments and factoring in transaction cost, drawdown, and position type (long or short), while accounting for multiple peak values in the price.'
          },
          {
            name: 'Trailing Stop Adjusted Reward',
            description:
              'Calculates a profit considering a trailing stop for managing risk in financial actions. It incorporates transaction cost, drawdown, and position type (long or short), while also tracking multiple peak values in the price.'
          },
          {
            name: 'Profit with Risk Management',
            description:
              'Determines profit considering risk management strategies for financial actions. The calculation incorporates transaction costs, drawdown, and position type (long or short) and evaluates potential rewards.'
          },
          {
            name: 'Relative Risk-Adjusted Reward',
            description:
              'Calculates a risk-adjusted reward for financial actions with consideration for relative changes in asset prices. This function accounts for transaction costs, drawdown, and position type (long or short) while evaluating the potential reward based on relative changes in asset prices.'
          }
        ]
      }
    };
  },
  metaInfo() {
    return {
      title: this.rewardFunctions.title,
      meta: [
        {
          name: 'description',
          content: this.rewardFunctions.description,
        },
      ],
    };
  },
  methods: {
    zoomIn(event) {
      event.currentTarget.classList.add('zoomed');
    },
    zoomOut(event) {
      event.currentTarget.classList.remove('zoomed');
    }
  }
};
</script>

  
<style scoped>
.pattern-card {
  background-color: #ffc700;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin: 1em;
  padding: 1em;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  cursor: pointer;
  width: calc(100% - 2em);
  margin: 1em auto;
}

.pattern-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.pattern-card h3 {
  font-size: 1.6em;
  color: #343a40;
  margin-bottom: 0.5em;
}

.pattern-card p {
  font-size: 1.2em;
  color: #6c757d;
  margin-bottom: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*
@media screen and (max-width: 48em) {
  .pattern-card {
    width: calc(100% - 2em);
  }
  h1 {
    font-size: 2em; 
  }
  .h2{
    font-size: medium;
  }
  .pattern-card h3 {
    font-size: 1.2em; 
  }

  .pattern-card p {
    font-size: 1em; 
  }
}


@media screen and (min-width: 48em) and (max-width: 62em) {
  .pattern-card {
    width: calc(48% - 2em);
  }
}

@media screen and (min-width: 62em) {
  .pattern-card {
    width: calc(30% - 2em);
  }
}*/
</style>
